import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import zhTw from 'antd/locale/zh_TW';
import { ConfigProvider } from 'antd';

import RouterWrap from 'Dashboard/router/RouterWrapper';
import Login from 'Dashboard/view/Login';
// 發訊管理
import MessageCenter from 'Dashboard/view/message/MessageCenter';
import MessageCheck from 'Dashboard/view/message/MessageCheck';
import PushStatistics from 'Dashboard/view/message/PushStatistics';
// 匯客+後台資料管理
import RecommendProduct from 'Dashboard/view/recommend/RecommendProduct';
// 權限管理
import Permissions from 'Dashboard/view/manage/Permissions';
import Sales from 'Dashboard/view/manage/Sales';
//財顧管理
import GoldenfpQuestion from 'Dashboard/view/goldenfp/GoldenfpQuestion';
import GoldenfpList from 'Dashboard/view/goldenfp/GoldenfpList';
//五大挑戰
import AllRank from 'Dashboard/view/goldenChallenges/AllRank';
import Award from 'Dashboard/view/goldenChallenges/Award';
import Event from 'Dashboard/view/goldenChallenges/Event';
//高爾夫球
import PlayerList from 'Dashboard/view/golf/PlayerList';
//業務服務
import NPSStatistics from 'Dashboard/view/saleService/NPSStatistics';
import Assistant from 'Dashboard/view/saleService/Assistant';
import VipService from 'Dashboard/view/saleService/VipService';
import ChubbPizza from 'Dashboard/view/saleService/ChubbPizza';
import { returnRoute, setAuthorization } from 'Dashboard/authorization/Authorization';

const AppRoute = (props) => {
    useEffect(() => {
        if (window.location.pathname == '/dashboard/login') {
            return;
        } else {
            let role = localStorage.getItem('role');
            if (role !== null || role !== undefined) {
                if (setAuthorization(window.location.pathname) !== undefined) {
                    if (!setAuthorization(window.location.pathname).includes(role)) {
                        window.location.href = `/dashboard/${returnRoute(role)}`;
                    }
                } else {
                    localStorage.clear();
                    window.location.href = '/dashboard/login';
                }
            }
        }
    }, []);
    return (
        <ConfigProvider
            locale={zhTw}
            theme={{
                token: {
                    colorPrimary: '#315176',
                    fontSize: '16px',
                },
            }}>
            <Router>
                <RouterWrap>
                    <Route exact path='/dashboard/login'>
                        <Login />
                    </Route>
                    {/*發訊管理*/}
                    <Route exact path='/dashboard/message_center'>
                        <MessageCenter mainTitle='發訊管理' title='發訊中心' />
                    </Route>
                    <Route exact path='/dashboard/message_check'>
                        <MessageCheck mainTitle='發訊管理' title='訊息審核' />
                    </Route>
                    <Route exact path='/dashboard/push_statistics'>
                        <PushStatistics mainTitle='發訊管理' title='推播統計' />
                    </Route>
                    {/*匯客+後台資料管理*/}
                    <Route exact path='/dashboard/recommend_product'>
                        <RecommendProduct mainTitle='匯客+後台資料管理' title='公勝推薦商品' />
                    </Route>
                    {/*權限管理*/}
                    <Route exact path='/dashboard/sales'>
                        <Sales mainTitle='權限管理' title='業務員管理' />
                    </Route>
                    <Route exact path='/dashboard/permissions'>
                        <Permissions mainTitle='權限管理' title='權限管理' />
                    </Route>
                    {/*業務服務管理*/}
                    <Route exact path='/dashboard/nps_data'>
                        <NPSStatistics mainTitle='業務服務管理' title='行專 NPS 統計表' />
                    </Route>
                    <Route exact path='/dashboard/nps_assistant'>
                        <Assistant mainTitle='業務服務管理' title='行專管理' />
                    </Route>
                    <Route exact path='/dashboard/vip_service'>
                        <VipService mainTitle='業務服務管理' title='尊榮預約通道管理' />
                    </Route>
                    <Route exact path='/dashboard/chubb_pizza'>
                        <ChubbPizza mainTitle='業務服務管理' title='必勝客管理' />
                    </Route>
                    {/*財顧管理*/}
                    <Route exact path='/dashboard/goldenfp_list'>
                        <GoldenfpList mainTitle='財顧管理' title='論壇簽到表' />
                    </Route>
                    <Route exact path='/dashboard/goldenfp_question'>
                        <GoldenfpQuestion mainTitle='財顧管理' title='論壇提問表' />
                    </Route>
                    {/*五大挑戰管理*/}
                    <Route exact path='/dashboard/challenges_rank'>
                        <AllRank />
                    </Route>
                    <Route exact path='/dashboard/challenges_event'>
                        <Event />
                    </Route>
                    <Route exact path='/dashboard/challenges_award'>
                        <Award />
                    </Route>
                    {/* 高爾夫球 */}
                    <Route exact path='/dashboard/golf_player'>
                        <PlayerList mainTitle='高爾夫球管理' title='匯出名單' />
                    </Route>
                </RouterWrap>
            </Router>
        </ConfigProvider>
    );
};
export default AppRoute;
