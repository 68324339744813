import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Countfunc from '../../assets/javascript/countfunc';
import LineLogin from '../../assets/javascript/lineLogin';
import Loading from '../../components/Loading';
import { toCurrency } from '../../assets/javascript/calTool';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
//加碼
const ChallengesOfGolden = () => {
    let [isLoading, setIsLoading] = useState(true)
    let [eventButtonDis, setEventButtonDis] = useState(true) //true:鎖住 false:解鎖
    let [modalShow, setModalShow] = useState(true)

    useEffect(() => {
        LineLogin('/Challenges_of_Golden')
        Countfunc('公勝 30 五大挑戰', 51)
        let API = `${process.env.REACT_APP_GOLDEN_API5000}CheckState`
        axios.get(API, {
            withCredentials: true,
            headers: { 'Access-Control-Allow-Credentials': 'true' }
        })
            .then((res) => {
                setTimeout(() => {
                    if (res.data.ResponseCode === '-1') {
                        localStorage.setItem('page', '/Challenges_of_Golden');
                        window.location.href = '/login'
                        return
                    }
                }, Number(process.env.REACT_APP_TIMEOUT))
            })
            .catch((err) => {
                // alert('請重新整理')
            })
    }, []);

    let [totalMember, setTotalMember] = useState([{ r_total: 0 }]) //總人數
    let [eventMember, setEventMember] = useState([{ r_total: 0 }]) //特殊活動總人數
    let [totalDonate, setTotalDonate] = useState(0) //總額

    let [data, setData] = useState([]) //最新十筆紀錄
    const handleAPI = {
        getLatestTen: function () { //取得最新十筆
            let API = `${process.env.REACT_APP_GOLDEN_Challenges_API}latest_ten`
            axios.get(API)
                .then((res) => {
                    if (res.data.code == 0) {
                        setIsLoading(false)
                        setData(res.data.data)
                    } else {
                        alert('錯誤!!')
                        setData([])
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        getTotalPlayer: function () { //取得總人數
            let memberAPI = `${process.env.REACT_APP_GOLDEN_Challenges_API}total_player`
            axios.get(memberAPI)
                .then((res) => {
                    if (res.data.code == 0) {
                        setIsLoading(false)
                        setTotalMember(res.data.data)
                        setEventMember(res.data.data_event)
                    } else {
                        alert('錯誤!!')
                        setTotalMember([{ r_total: 0 }])
                        setEventMember([{ r_total: 0 }])
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        getTotalDonate: function () { //取得總額
            let API = `${process.env.REACT_APP_GOLDEN_Challenges_API}total_donate`
            axios.get(API)
                .then((res) => {
                    let { data } = res
                    if (res.data.code == 0) {
                        setIsLoading(false)
                        setTotalDonate(Number(data?.data[0]?.Donate_Total) + Number(data?.data_event[0]?.Donate_Total_2) + Number(data?.last_year))
                    } else {
                        alert('錯誤!!')
                        setIsLoading(false)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }
    useEffect(() => {
        handleAPI.getLatestTen();
        handleAPI.getTotalPlayer();
        handleAPI.getTotalDonate();
    }, [])

    function start() {
        //   按下 start 後 id 為 timer 的 DIV 內容可以開始倒數到到 0。 
        var timer = document.querySelector("#timer");
        var number = 5;
        setInterval(function () {
            number--;
            if (number <= 0)
                number = 0;
            timer.innerText = number + 0
        }, 1000);
    }
    const handleEvent = {
        goToPage: function (e) { //button 連結
            let { value, dataset } = e.target;
            if (value === '活動登入' || dataset.value === '活動登入') {
                window.location.href = '/Challenges_of_Golden_Form';
            } else if (value === '個人成果' || dataset.value === '個人成果') {
                window.location.href = '/Challenges_of_Golden_Award';
            } else if (value === '狂人排行' || dataset.value === '狂人排行') {
                window.location.href = '/Challenges_of_Golden_Rank';
            } else if (value === '區部排行' || dataset.value === '區部排行') {
                window.location.href = '/Challenges_of_Golden_RankDept';
            }
        },
        hideCenterName: function (name) { //中間名隱藏
            let newName;
            if (name.length === 2) {
                newName = name.substr(0, 1) + '○'
            } else if (name.length > 2) {
                let char = '';
                for (let i = 0, len = name.length - 2; i < len; i++) {
                    char += '○'
                }
                newName = name.substr(0, 1) + char + name.substr(-1, 1)
                //取名字首位 + 中間名隱藏 + 取名字最後位子
            }
            return newName
        },
        joinSocialGroup: function (e) { //社群官方群組
            let { value, nodeName } = e.target
            if (nodeName !== 'BUTTON') {
                return
            }
            if (value === '游泳') {
                window.location.href = 'https://line.me/ti/g/gB5eWJlls1'
            } else if (value === '跑步') {
                window.location.href = 'https://line.me/ti/g/G8fTd53nNe'
            } else if (value === '健行') {
                window.location.href = 'https://line.me/ti/g/_5JBQM4W92'
            } else if (value === '自行車') {
                window.location.href = 'https://line.me/ti/g/c_GQvZ2dI8'
            } else if (value === '登山') {
                window.location.href = 'https://line.me/ti/g/ap6RGBLaeE'
            } else if (value === 'strava') {
                window.location.href = 'https://www.strava.com/clubs/GOLDEN30'
            }
        },
        percentCal: function () { //百分比計算
            let goalTotal = 3000000 //目標金額
            return Math.trunc((Number(totalDonate) / goalTotal) * 100)
        },
        modalClose: function () { //關閉 modal
            setModalShow(false)
        }
    }

    const medal = (value) => {
        if (!value) {
            return ''
        } else if (
            value === 'h_premium' ||
            value === 'h_platinum' ||
            value === 'h_gold' ||
            value === 'h_silver' ||
            value === 'h_copper' ||
            value === 'h_wood' ||
            value === 'j_premium' ||
            value === 'j_platinum' ||
            value === 'j_gold' ||
            value === 'j_silver' ||
            value === 'j_copper' ||
            value === 'j_wood' ||
            value === 'b_premium' ||
            value === 'b_platinum' ||
            value === 'b_gold' ||
            value === 'b_silver' ||
            value === 'b_copper' ||
            value === 'b_wood' ||
            value === 'c_premium' ||
            value === 'c_platinum' ||
            value === 'c_gold' ||
            value === 'c_silver' ||
            value === 'c_copper' ||
            value === 'c_wood' ||
            value === 's_premium' ||
            value === 's_platinum' ||
            value === 's_gold' ||
            value === 's_silver' ||
            value === 's_copper' ||
            value === 's_wood') {
            return <img src={require(`../../assets/images/challengesofGolden/award/${value}.png`).default} alt={value} className='img-fluid py-1' width='35px' />
        } else {
            return ''
        }
    }

    useEffect(() => {
        setTimeout(() => {
            setEventButtonDis(false)
        }, 5000)
    }, [])

    return (
        <>

            <Loading isLoading={isLoading} />
            <div className='container'>
                <div>
                    <div className='text-light' style={{ marginBottom: '20px' }}>
                        {/* <h3 className='challenge-title'>感謝您的參與，<br />我們 2024 再見 !</h3> */}
                        <h3 className='challenge-title'>超越世代，聚勢未來  <br />Action for Future</h3>
                        <p className='challenge-subtitle fw-bolder'>每達成 1K，為你捐出 1 元 <br /> 一起用生命點亮生命</p>
                    </div>
                    <div>
                        <p className='text-light fw-bolder' style={{ lineHeight: '32px' }}>累積參與人數：<span className='text-challenges-yellow'>{toCurrency(totalMember[0].r_total + eventMember[0].r_total)} 人</span></p>
                        <p className='text-light fw-bolder' style={{ lineHeight: '32px' }}>捐款提撥進度：<span className='text-challenges-yellow'>{toCurrency(Number(totalDonate))} 元</span></p>
                        {moment().valueOf() >= moment('2024-12-31 23:59:00').valueOf() && <p className='mt-3 text-light'>【挑戰已關閉】</p>}
                    </div>

                </div>
                <div>
                    <div className='d-flex align-items-center justify-content-end'>
                        <img className='challenge-main-img' src={require('../../assets/images/challengesofGolden/run.svg').default} alt='跑步' style={{ marginRight: '48px' }} />
                    </div>
                    <div className="progress">
                        {totalDonate == 0 ? '' :
                            <div className="progress-bar" role="progressbar" aria-valuenow={`${handleEvent.percentCal()}%`} aria-valuemin="0" aria-valuemax="100" style={{ width: `${handleEvent.percentCal()}%` }}>
                                {
                                    handleEvent.percentCal() < 10 ? '' : <span>{handleEvent.percentCal()}%</span>
                                }
                            </div>
                        }
                    </div>
                    <div className='d-flex align-items-center position-relative'>
                        <div className={`challenge-progress-goal ${handleEvent.percentCal() >= 100 ? 'challenge-progress-goal-success' : ''}`}>
                            <p className='goal-text'>300萬</p>
                        </div>
                    </div>
                    <div className='d-table' style={{ height: '55px' }}></div>
                    <div className='challenge-button-group d-flex justify-content-between justify-content-lg-center'>
                        {
                            moment().valueOf() >= moment('2024-12-31 23:59:00').valueOf() ? '' :
                                <button type='button' className='btn btn-challenges-yellow fw-bolder px-1 px-xs-2 px-md-3 mx-1 mx-lg-3' value='活動登入' onClick={handleEvent.goToPage}>
                                    <div className='d-flex justify-content-center' data-value='活動登入'><img data-value='活動登入' src={require('../../assets/images/challengesofGolden/icon/login.svg').default} className='d-block img-fluid' alt='活動登入' width='55%' /></div>
                                    活動登入
                                </button>
                        }
                        <button type='button' className='btn btn-challenges-yellow fw-bolder px-1 px-xs-2 px-md-3 mx-1 mx-lg-3' value='個人成果' onClick={handleEvent.goToPage}>
                            <div className='d-flex justify-content-center' data-value='個人成果'><img data-value='個人成果' src={require('../../assets/images/challengesofGolden/icon/award.svg').default} className='d-block img-fluid' alt='個人成果' width='40%' /></div>
                            個人成果
                        </button>
                        <button type='button' className='btn btn-challenges-yellow fw-bolder px-1 px-xs-2 px-md-3 mx-1 mx-lg-3' value='狂人排行' onClick={handleEvent.goToPage}>
                            <div className='d-flex justify-content-center' data-value='狂人排行'><img data-value='狂人排行' src={require('../../assets/images/challengesofGolden/icon/ranking.svg').default} className='d-block img-fluid' alt='狂人排行' width='55%' /></div>
                            狂人排行
                        </button>
                        <button type='button' className='btn btn-challenges-yellow fw-bolder px-1 px-xs-2 px-md-3 mx-1 mx-lg-3' value='區部排行' onClick={handleEvent.goToPage}>
                            <div className='d-flex justify-content-center' data-value='區部排行'><img data-value='區部排行' src={require('../../assets/images/challengesofGolden/icon/ranking.svg').default} className='d-block img-fluid' alt='區部排行' width='55%' /></div>
                            區部排行
                        </button>
                    </div>
                </div>
                {/* 下面暫時勿動 */}
                {
                    data.length === 0 ?
                        '' : <p className='text-light pt-2'>最近 10 筆紀錄 ▼</p>
                }
                <div className="table-responsive-xxl text-center mt-3">
                    <table className="table table-striped table-light align-middle">
                        <thead>
                            <tr>
                                <th className="text-center" width='200' style={{ whiteSpace: 'nowrap' }}>姓名</th>
                                <th className="text-center" width='130' style={{ whiteSpace: 'nowrap' }}>類型</th>
                                <th className="text-center" width='130' style={{ whiteSpace: 'nowrap' }}>里程紀錄</th>
                                <th className="text-center" width='130' style={{ whiteSpace: 'nowrap' }}>加碼</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.length === 0 ?
                                <tr className=''>
                                    <td className="text-dark-blue p-0" colSpan="4" style={{ whiteSpace: 'nowrap' }}>暫無紀錄</td>
                                </tr> : ''}
                            {
                                data.map((item, index) => {
                                    return (
                                        <>
                                            <tr className=''>
                                                <td className="text-dark-blue p-0" style={{ whiteSpace: 'nowrap' }}>{item.r_buscode} / {item.r_username}</td>
                                                <td className="text-dark-blue p-0" style={{ whiteSpace: 'nowrap' }}>{item.r_type}</td>
                                                <td className="text-dark-blue p-0" style={{ whiteSpace: 'nowrap' }}>{item.r_records} {item.r_type === '登山' || item.r_type === '游泳' ? 'M' : 'K'}</td>
                                                <td className="text-dark-blue p-0" style={{ whiteSpace: 'nowrap' }}>
                                                    {medal(item.r_medal)}
                                                </td>
                                            </tr>
                                        </>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                {/* 社群群組 icon */}
                <div className='challenge-social py-4 row' onClick={handleEvent.joinSocialGroup}>
                    <div className='col-12 col-lg-6 line-group mb-3'>
                        <p className='text-light mb-3'>LINE 群組 ▼</p>
                        <div className='d-flex justify-content-between justify-content-lg-start'>
                            <div className='mx-lg-3'>
                                <button type='button' className='btn s-icon' value='游泳'></button>
                                <p className='text-light text-center mt-1'>游泳</p>
                            </div>
                            <div className='mx-lg-3'>
                                <button type='button' className='btn j-icon' value='跑步'></button>
                                <p className='text-light text-center mt-1'>跑步</p>
                            </div>
                            <div className='mx-lg-3'>
                                <button type='button' className='btn h-icon' value='健行'></button>
                                <p className='text-light text-center mt-1'>健行</p>
                            </div>
                            <div className='mx-lg-3'>
                                <button type='button' className='btn b-icon' value='自行車'></button>
                                <p className='text-light text-center mt-1'>自行車</p>
                            </div>
                            <div className='mx-lg-3'>
                                <button type='button' className='btn c-icon' value='登山'></button>
                                <p className='text-light text-center mt-1'>登山</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-lg-6 strava-group'>
                        <p className='text-light mb-3'>Strava 運動社群 ▼</p>
                        <button type='button' className='btn strava-icon' value='strava'></button>
                    </div>
                </div>
            </div>
            {/* 活動彈跳視窗 */}
            <Modal show={false} centered>
                <Modal.Body className='text-center'>
                    <button className='btn btn-challenges-blue mt-2' disabled={eventButtonDis && 'disabled'} onClick={handleEvent.modalClose}><span id='timer' className='fw-bolder text-light me-2'></span>關閉</button>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default ChallengesOfGolden