import moment from 'moment';

export function handleStepData(data) {
    const getTagList = Object.keys(data)
        .filter((val) => {
            return val.includes('Date');
        })
        .map((item) => {
            const stepNum = item?.split('_')[2];
            return {
                step: `S${stepNum}`,
                text: data[item],
            };
        });

    const firstNullIndex = getTagList.findIndex((tag) => tag.text === null || typeof tag.text === 'number');

    function handleStepShowData(item) {
        const acceptState = (state) => {
            switch (state) {
                case 1:
                    return '成交';
                case 2:
                    return '未成交';
                case 3:
                case 0:
                    return '受理中';
                default:
                    return '';
            }
        };

        switch (item.step) {
            case 'S1':
                return `匯入時間\n${moment(item.text).format('YYYY-MM-DD')}`;
            case 'S2':
            case 'S3':
            case 'S4':
                return `執行日期\n${moment(item.text).format('YYYY-MM-DD')}`;
            case 'S5':
                return `受理編號\n${item.text}`;
            case 'S6':
                return `受理狀態\n${acceptState(item.text)}`;
            default:
                break;
        }
    }

    let finalStepData = getTagList.map((item, index) => {
        if (index < firstNullIndex) {
            item.text = handleStepShowData(item);
            item.disabled = false;
        } else {
            if (getTagList.length === firstNullIndex + 1) {
                //判斷是否為最後階段
                item.text = handleStepShowData(item);
                item.disabled = false;
            } else {
                if (index === firstNullIndex) {
                    item.text = '進行中';
                    item.disabled = false;
                } else {
                    item.text = '尚未開始';
                    item.disabled = true;
                }
            }
        }
        return item;
    });

    return { finalStepData, firstNullIndex };
}
