import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toCurrency, dateChange } from '../../assets/javascript/calTool';
import Loading from '../../components/Loading';
import { SUM } from 'formulajs';
import LineLogin from '../../assets/javascript/lineLogin';
import { Modal } from 'react-bootstrap'
import CardImg from '../../assets/images/challengesofGolden/challenge_card.jpg';
import moment from 'moment';
import html2canvas from "html2canvas";
import busCodeToName from '../../assets/document/busCode.json';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

// 符合【特級】修改、刪除功能隱藏 --> 判斷 1:打勾為符合特級
const ChallengesofGoldenAward = () => {
    const token = document.cookie.replace(/(?:(?:^|.*;\s*)userDataToken\s*=\s*([^;]*).*$)|^.*$/, '$1');
    if (token === '') {
        localStorage.setItem('page', '/Challenges_of_Golden_Award');
        window.location.href = '/login'
    }
    useEffect(() => {
        LineLogin('/Challenges_of_Golden_Award')
        let API = `${process.env.REACT_APP_GOLDEN_API5000}CheckState`
        axios.get(API, {
            withCredentials: true,
            headers: { 'Access-Control-Allow-Credentials': 'true' }
        })
            .then((res) => {
                setTimeout(() => {
                    if (res.data.ResponseCode === '-1') {
                        localStorage.setItem('page', '/Challenges_of_Golden_Award');
                        window.location.href = '/login'
                        return
                    }
                }, Number(process.env.REACT_APP_TIMEOUT))
            })
            .catch((err) => {
                // alert('請重新整理')
            })
    }, []);
    let name = JSON.parse(unescape(token)).Name;
    let busCode = JSON.parse(unescape(token)).BusCode
    let lineId = JSON.parse(unescape(token)).LineId

    //LINE login 驗證
    let [isLoading, setIsLoading] = useState(true)
    let [awardLevel] = useState([{ level: 'premium', name: '特級' }, { level: 'platinum', name: '白金' }, { level: 'gold', name: '金' }, { level: 'silver', name: '銀' }, { level: 'copper', name: '銅' }, { level: 'wood', name: '木' }])
    let [typeData] = useState([{ type: 's_', name: '游泳' }, { type: 'j_', name: '跑步' }, { type: 'h_', name: '健行' }, { type: 'b_', name: '自行車' }, { type: 'c_', name: '登山' }])
    let [typeOption, setTypeOption] = useState('s_')
    let [option, setOption] = useState('活動紀錄')
    let [awardData, setAwardData] = useState([]) //獎章認證
    let [detailData, setDetailData] = useState([]) //活動紀錄
    let [eventData, setEventData] = useState([]) //特殊活動紀錄
    let [totalDonate, setTotalDonate] = useState(0) //累計捐贈(個人)
    let [eventDonate, setEventDonate] = useState(0) //特殊活動捐贈(個人)
    let [totalAward, setTotalAward] = useState(0) //獲得獎章
    let [totalEvent, setTotalEvent] = useState(0) //完成活動
    let [EventNum, setEventNum] = useState(0) //參加特殊活動次數
    let [modalShow, setModalShow] = useState(false); //true 開啟;false 關閉
    let [updateData, setUpdateData] = useState({ r_id: '', r_date: '', r_type: '', r_records: '', r_challenges_name: '', r_premium_status: '' })

    //@ 各項達標標準文字
    const uptoStandard = {
        h_premium: '文化健行參與達3天以上',
        h_platinum: '20K',
        h_gold: '15K',
        h_silver: '10K',
        h_copper: '一日 5K',
        h_wood: '一日 3K',

        j_premium: '一日 42K 全馬',
        j_platinum: '一日 21K 半馬',
        j_gold: '一日 15K',
        j_silver: '一日 10K 路跑',
        j_copper: '一日 5K 路跑',
        j_wood: '一日 3K 路跑',

        b_premium: '雙塔、北高、武嶺、環島',
        b_platinum: '一日 100K',
        b_gold: '一日 50K',
        b_silver: '一日 30K',
        b_copper: '一日 20K',
        b_wood: '一日 10K',

        c_premium: '台灣百岳:名單詳見"健行筆記"',
        c_platinum: '一日爬升 2000M',
        c_gold: '一日爬升 1000M',
        c_silver: '一日爬升 500M',
        c_copper: '一日爬升 300M',
        c_wood: '一日爬升 100M',

        s_premium: '泳渡日月潭、三鐵游泳',
        s_platinum: '一次 2000M',
        s_gold: '一次 1000M',
        s_silver: '一次 500M',
        s_copper: '一次 300M',
        s_wood: '浮板 100M'
    }

    const handleAPI = {
        getData: function () { //取得所有 data
            setIsLoading(true)
            let API = `${process.env.REACT_APP_GOLDEN_Challenges_API}personal_medal/${busCode}/${name}/${lineId}`
            axios.get(API)
                .then((res) => {
                    if (res.data.code == 0) {
                        if (res.data.data == 0) {
                            setAwardData([])
                            setTotalDonate(0)
                            setEventDonate(0)
                        } else {
                            setAwardData(res.data.data)
                            setTotalDonate(res.data.data[0].donate)
                            setEventDonate(res.data.sql_event_DT[0].Donate_Total_2)
                        }
                        if (res.data.data_detail == 0) {
                            setDetailData([])
                        } else {
                            setDetailData(res.data.data_detail)
                        }
                        if (res.data.data_detail_T == 0) {
                            setTotalEvent(0) //總完成活動數
                        } else {
                            setTotalEvent(res.data.data_detail_T[0]['count(*)'])
                        }
                        if (res.data.sql_event_T == 0) {
                            setEventNum(0) //總完成活動數
                        } else {
                            setEventNum(res.data.sql_event_T[0]['count(*)'])
                        }
                        if (res.data.date_event_detail == 0) {
                            setEventData([])
                        } else {
                            setEventData(res.data.date_event_detail)
                        }
                        setIsLoading(false)
                    } else {
                        alert('個人成果資料錯誤 !!')
                        setAwardData([])
                        setTotalDonate(0)
                        setEventDonate(0)
                        setDetailData([])
                        setIsLoading(false)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    useEffect(() => {
        handleAPI.getData();
    }, [])

    useEffect(() => { //【完成活動】、【獲得獎章】數量計算
        let obj = { ...awardData[0] }
        let arr = [];
        let total = 0; //完成活動數
        for (let key in obj) {
            if (!awardData[0].hasOwnProperty(key)) {
                continue;
            }
            if (key == 'donate') { obj[key] = 0 } //總捐贈額 不列入計算數裡，因此需歸 0
            if (key == 'b_donate') { obj[key] = 0 } // b_donate 不列入計算數裡，因此需歸 0
            arr.push(obj[key]);
            if (obj[key] !== 0) { // 計算完成活動數，假設數值不為 0 則 +1
                total += 1
            }
        }
        setTotalAward(SUM(arr)) //總獲得獎章數
    }, [awardData])

    const handleEvent = {
        changeOption: function (e) { //切換 button 選項(活動紀錄、獎章認證)
            let { value, nodeName } = e.target;
            if (nodeName !== 'BUTTON') {
                return
            }
            // if (value == '產生個人圖卡') {
            //     handleAPI.getDonate()
            // }
            setOption(value)
            setTypeOption('s_')
        },
        changeTypeOption: function (e) { //獎章認證-類型選項切換
            let { value, nodeName } = e.target;
            if (nodeName !== 'BUTTON') {
                return
            }
            setTypeOption(value)
        },
        returnHomePage: function (e) { //回首頁
            e.preventDefault();
            window.location.href = '/Challenges_of_Golden'
        },
        returnTypeName: function (value) { //數值轉換對應類型文字
            if (value == '1') {
                return '健行'
            } else if (value == '2') {
                return '跑步'
            } else if (value == '3') {
                return '自行車'
            } else if (value == '4') {
                return '登山'
            } else if (value == '5') {
                return '游泳'
            }
        },
        changeMaxRadio: function (e) { //特級認定修改
            setUpdateData({ ...updateData, r_premium_status: e.target.value })
        },
        clickSend: function () { //修改儲存
            if (updateData.r_id === '' || updateData.r_date === '' || updateData.r_type === '' || updateData.r_records === '' || updateData.r_records == '0') {
                alert('請確認修改紀錄，不能為空白或 0')
                return
            } else if (updateData.r_premium_status == '1') {
                if (updateData.r_challenges_name == '0' || updateData.r_challenges_name === '' || updateData.r_challenges_name === null || updateData.r_challenges_name === undefined) {
                    alert('請正確填寫完成活動名稱')
                    return
                }
            }
            setIsLoading(true)
            let API = ''
            if (updateData.r_premium_status == '1') {
                API = `${process.env.REACT_APP_GOLDEN_Challenges_API}update_records/${updateData.r_id}/${updateData.r_date}/${updateData.r_type}/${updateData.r_records}/${updateData.r_premium_status}/${updateData.r_challenges_name}/${busCode}/${name}/${lineId}`
            } else if (updateData.r_premium_status == '0') {
                API = `${process.env.REACT_APP_GOLDEN_Challenges_API}update_records/${updateData.r_id}/${updateData.r_date}/${updateData.r_type}/${updateData.r_records}/${updateData.r_premium_status}/0/${busCode}/${name}/${lineId}`
            }
            axios.patch(API)
                .then((res) => {
                    if (res.data.code == 0) {
                        setIsLoading(false)
                        setModalShow(false)
                        handleAPI.getData();
                    } else {
                        alert('修改錯誤!! 請重新修改')
                        setModalShow(false)
                        setIsLoading(false)
                        handleAPI.getData();
                    }
                })
                .catch((err) => {
                    alert('修改錯誤!! 請重新修改')
                    setModalShow(false)
                    setIsLoading(false)
                    console.log(err)
                })
        },
        stateEvent: function (e) { //修改、刪除 狀態按鈕
            let { value, nodeName, dataset } = e.target;
            if (nodeName !== 'BUTTON') {
                return
            }
            if (value === '刪除') {
                setIsLoading(true)
                let API = `${process.env.REACT_APP_GOLDEN_Challenges_API}update_records/${dataset.id}`
                axios.delete(API)
                    .then((res) => {
                        if (res.data.code == 0) {
                            setIsLoading(false)
                            handleAPI.getData();
                        } else {
                            alert('刪除錯誤!!')
                            setIsLoading(false)
                            handleAPI.getData();
                        }
                    })
                    .catch((err) => {
                        alert('刪除錯誤!!')
                        setIsLoading(false)
                        console.log(err)
                    })
            } else if (value === '修改') {
                setUpdateData({
                    r_id: dataset.id,
                    r_date: dataset.date,
                    r_type: dataset.type,
                    r_records: dataset.recode,
                    r_premium_status: dataset.premium, //特級認定
                    r_challenges_name: dataset.challenges //完成活動名稱
                })
                setModalShow(true)
            }
        },
        modalClose: function () {
            setUpdateData({ r_id: '', r_date: '', r_type: '', r_records: '' })
            setModalShow(false)
        },
        downloadImg: function () {
            function isMobile() {
                try { document.createEvent("TouchEvent"); return true; }
                catch (e) { return false; }
            }
            let IMAGE_URL;
            function screen() {
                let shareContent = document.getElementById('challenge-card');//截圖範圍
                let width = shareContent.offsetWidth; //寬度 shareContent.offsetWidth
                let height = shareContent.offsetHeight; // 高度 shareContent.offsetHeight

                let canvas = document.createElement("canvas");
                let scale = 4;
                canvas.width = width * scale; //寬度 * 縮放
                canvas.height = height * scale; //高度 * 縮放
                canvas.getContext("2d")
                let ctx = canvas.getContext("2d");
                ctx.webkitImageSmoothingEnabled = false;
                ctx.mozImageSmoothingEnabled = false;
                ctx.imageSmoothingEnabled = false;

                let opts = {
                    dpi: 144,
                    scale: scale,
                    canvas: canvas,
                    logging: true,
                    width: width, //dom 原始寬度
                    height: height, //dom 原始高度
                    backgroundColor: 'transparent',
                    useCORS: true, //解決圖片跨域問題
                    allowTaint: true,
                    removeContainer: true,
                };
                html2canvas(shareContent, opts).then(function (canvas) {
                    var image = new Image();
                    image.src = canvas.toDataURL("image/png");
                    document.getElementById('img-out').appendChild(image)
                    //--ios or android
                    let u = navigator.userAgent;
                    let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android終端
                    let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios終端
                    if (isAndroid) {
                        document.getElementById('text').innerHTML = `<p class="text-center py-3 fw-bolder">長壓選擇儲存照片~</p>`
                    } else if (isiOS) {
                        document.getElementById('text').innerHTML = `<p class="text-center py-3 fw-bolder">長壓選擇加入照片~</p>`
                    }
                    //--ios or android
                    IMAGE_URL = canvas.toDataURL("image/png");
                    downloadBase64(IMAGE_URL, '我的公勝30五大挑戰_個人成果.png');
                    if (isMobile()) {
                        document.getElementById("card-table").setAttribute("style", "display:none")
                    } else {
                        document.getElementById("img-out").setAttribute("style", "display:none")
                        document.getElementById("text").setAttribute("style", "display:none")
                    }

                })
            }

            function dataURLtoBlob(dataurl) {
                let arr = dataurl.split(','),
                    mime = arr[0].match(/:(.*?);/)[1],
                    bstr = atob(arr[1]),
                    n = bstr.length,
                    u8arr = new Uint8Array(n)
                while (n--) {
                    u8arr[n] = bstr.charCodeAt(n)
                }
                return new Blob([u8arr], { type: mime })
            }

            function downloadBase64(dataUrl, filename) {
                let href
                const downloadLink = document.createElement('a');
                try {
                    let blob = dataURLtoBlob(dataUrl);
                    let href = window.URL.createObjectURL(blob);
                    downloadLink.download = filename
                    downloadLink.href = href
                    downloadLink.click()
                } catch (err) {
                } finally {
                    if (href) {
                        window.URL.revokeObjectURL(href);
                    }
                    // downloadLink.remove()
                }
            }
            screen();
        },
        calStrLength: function (str) {
            return str.length
        }
    }

    let activeStyle = {
        borderBottom: '2px solid #fff',
        paddingBottom: '4px',
    }

    return (
        <>
            <Loading isLoading={isLoading} />
            <div className='container challenge'>
                <div className='d-flex justify-content-between justify-content-lg-start mb-3'>
                    <div className='me-lg-5'>
                        <h3 className='challenge-title text-light'>個人成果</h3>
                        <p className='text-light fw-bolder' style={{ lineHeight: '32px' }}>累計捐款：<span className='text-challenges-yellow'>{toCurrency(Number(totalDonate) + Number(eventDonate))} 元</span></p>
                        <p className='text-light fw-bolder' style={{ lineHeight: '32px' }}>已登錄活動次數：<span className='text-challenges-yellow'>{toCurrency(totalEvent)} 次</span></p>
                        <p className='text-light fw-bolder' style={{ lineHeight: '32px' }}>參與特殊活動次數：<span className='text-challenges-yellow'>{toCurrency(EventNum)} 次</span></p>
                        <p className='text-light fw-bolder' style={{ lineHeight: '32px' }}>獲得獎章：<span className='text-challenges-yellow'>{toCurrency(totalAward)} 個</span></p>
                    </div>
                    <img className='challenge-main-img_award' src={require('../../assets/images/challengesofGolden/award.svg').default} alt='成果' />
                </div>
                <div className='d-flex flex-wrap' onClick={handleEvent.changeOption}>
                    <button type='button' className={`btn ${option === '活動紀錄' ? 'btn-challenges-yellow' : 'btn-light'} text-dark fw-bolder m-2`} value='活動紀錄'>活動紀錄</button>
                    <button type='button' className={`btn ${option === '特殊活動紀錄' ? 'btn-challenges-yellow' : 'btn-light'} text-dark fw-bolder m-2`} value='特殊活動紀錄'>特殊活動紀錄</button>
                    <button type='button' className={`btn ${option === '獎章認證' ? 'btn-challenges-yellow' : 'btn-light'} text-dark fw-bolder m-2`} value='獎章認證'>獎章認證</button>
                    <button type='button' className={`btn ${option === '產生個人圖卡' ? 'btn-challenges-yellow' : 'btn-light'} text-dark fw-bolder  m-2`} value='產生個人圖卡'>產生個人圖卡</button>
                </div>
                {
                    option === '活動紀錄' ?
                        detailData.length == 0 ?
                            <p className='text-light text-center my-5'>無紀錄資料</p>
                            :
                            <>
                                <p className='text-light text-end d-sm-none my-2'>往右滑動 ＞</p>
                                <div className='table-responsive-xxl text-center mt-1 mt-sm-3' style={{ height: '370px', overflowY: 'scroll' }}>
                                    {
                                        <table className='table table-striped table-light align-middle'>
                                            <thead>
                                                <tr>
                                                    <th className='text-center' width='200' style={{ whiteSpace: 'nowrap' }}>日期</th>
                                                    <th className='text-center' width='130' style={{ whiteSpace: 'nowrap' }}>類型</th>
                                                    <th className='text-center' width='130' style={{ whiteSpace: 'nowrap' }}>里程</th>
                                                    <th className='text-center' width='130' style={{ whiteSpace: 'nowrap' }}>獎章</th>
                                                    <th className='text-center' width='130' style={{ whiteSpace: 'nowrap' }}>特級</th>
                                                    {
                                                        moment().valueOf() >= moment('2024-12-31 23:59:00').valueOf() ? '' :
                                                            <th className='text-center' width='80' style={{ whiteSpace: 'nowrap' }} colSpan='2'>功能</th>
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    detailData.sort((a, b) => {
                                                        return dateChange(a.r_date).split('-')[0] + dateChange(a.r_date).split('-')[1] + dateChange(a.r_date).split('-')[2] < dateChange(b.r_date).split('-')[0] + dateChange(b.r_date).split('-')[1] + dateChange(b.r_date).split('-')[2] ? 1 : -1
                                                    }).map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td className='text-dark-blue' style={{ whiteSpace: 'nowrap' }}>{dateChange(item.r_date)}</td>
                                                                <td className='text-dark-blue' style={{ whiteSpace: 'nowrap' }}>{handleEvent.returnTypeName(item.r_type)}</td>
                                                                <td className='text-dark-blue' style={{ whiteSpace: 'nowrap' }}>{item.r_records} {item.r_type == '4' || item.r_type == '5' ? 'M' : 'K'}</td>
                                                                <td className='text-dark-blue' style={{ whiteSpace: 'nowrap' }}>
                                                                    {
                                                                        item.r_medal === '' || item.r_medal === null || item.r_medal === undefined ? '' :
                                                                            <img src={require(`../../assets/images/challengesofGolden/award/${item.r_medal}.png`).default} alt={item.r_medal} className='img-fluid' width='38px' />
                                                                    }
                                                                </td>
                                                                <td className='text-dark-blue' style={{ whiteSpace: 'nowrap' }}>
                                                                    {item.r_premium_status == '1' ? <FontAwesomeIcon icon={faCheckCircle} /> : ''}
                                                                </td>
                                                                {
                                                                    moment().valueOf() >= moment('2024-12-31 23:59:00').valueOf() ? '' :
                                                                        <>
                                                                            {
                                                                                item.r_premium_status == '1' ?
                                                                                    <>
                                                                                        <td style={{ whiteSpace: 'nowrap' }}></td>
                                                                                        <td style={{ whiteSpace: 'nowrap' }}></td>
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        <td style={{ whiteSpace: 'nowrap' }} onClick={handleEvent.stateEvent}>
                                                                                            <button type='button' className='btn p-0 mx-1' value='修改'
                                                                                                data-id={item.r_id}
                                                                                                data-date={dateChange(item.r_date)}
                                                                                                data-type={item.r_type}
                                                                                                data-recode={item.r_records}
                                                                                                data-premium={item.r_premium_status}
                                                                                                data-challenges={item.r_challenges_name}
                                                                                            >修改</button>
                                                                                        </td>
                                                                                        <td style={{ whiteSpace: 'nowrap' }} onClick={handleEvent.stateEvent}>
                                                                                            <button type='button' className='btn p-0 text-danger del-btn mx-1' value='刪除'
                                                                                                data-id={item.r_id}
                                                                                                data-date={dateChange(item.r_date)} data-type={item.r_type} data-recode={item.r_records}></button>
                                                                                        </td>
                                                                                    </>
                                                                            }
                                                                        </>
                                                                }
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    }
                                </div>
                            </>
                        : ''
                }
                {
                    option === '特殊活動紀錄' ?
                        eventData.length == 0 ?
                            <p className='text-light text-center my-5'>無紀錄資料</p>
                            :
                            <>
                                <p className='text-light text-end d-sm-none my-2'>往右滑動 ＞</p>
                                <div className='table-responsive-xxl text-center mt-1 mt-sm-3' style={{ height: '370px', overflowY: 'scroll' }}>
                                    {
                                        <table className='table table-striped table-light align-middle'>
                                            <thead>
                                                <tr>
                                                    <th className='text-center' width='200' style={{ whiteSpace: 'nowrap' }}>活動名稱</th>
                                                    <th className='text-center' width='130' style={{ whiteSpace: 'nowrap' }}>捐款額</th>
                                                    <th className='text-center' width='130' style={{ whiteSpace: 'nowrap' }}>日期</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    eventData.sort((a, b) => {
                                                        return dateChange(a.r_date).split('-')[0] + dateChange(a.r_date).split('-')[1] + dateChange(a.r_date).split('-')[2] < dateChange(b.r_date).split('-')[0] + dateChange(b.r_date).split('-')[1] + dateChange(b.r_date).split('-')[2] ? 1 : -1
                                                    }).map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td className='text-dark-blue' style={{ whiteSpace: 'nowrap' }}>{item.s_event_name}</td>
                                                                <td className='text-dark-blue' style={{ whiteSpace: 'nowrap' }}>{toCurrency(item.s_event_donate)}</td>
                                                                <td className='text-dark-blue' style={{ whiteSpace: 'nowrap' }}>{dateChange(item.r_timest)}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    }
                                </div>
                            </>
                        : ''
                }
                {
                    option === '獎章認證' ?
                        awardData.length == 0 ?
                            <p className='text-light text-center my-5'>無獎章資料</p>
                            :
                            <>
                                <div className='challenge-type-button d-flex justify-content-between justify-content-lg-evenly mt-3 mb-2' onClick={handleEvent.changeTypeOption}>
                                    {
                                        typeData.map((item, index) => {
                                            return (
                                                <button type='button' className='btn text-light fw-bolder rounded-0' style={typeOption === item.type ? activeStyle : {}} value={item.type} key={item.name}>{item.name}</button>
                                            )
                                        })
                                    }
                                </div>
                                <div className='row'>
                                    {
                                        awardLevel.map((item, index) => {
                                            return (
                                                <div key={item.name} className={`col-4 col-lg-2 text-center my-3`}>
                                                    {
                                                        awardData.map((kitem, kindex) => {
                                                            if (kitem[`${typeOption}${item.level}`] == 0) {
                                                                return (
                                                                    <>
                                                                        <div className='challenge-not' key={kindex} title={uptoStandard[`${typeOption}${item.level}`]}>
                                                                            <img src={require(`../../assets/images/challengesofGolden/award/${typeOption}灰.png`).default} className='img-fluid challenge-award-img' alt='尚未解鎖等級' />
                                                                            <p className='text-light fw-bolder mt-2'>{item.name}</p>
                                                                        </div>
                                                                    </>
                                                                )
                                                            } else {
                                                                return (
                                                                    <>
                                                                        <div className='challenge-not' key={kindex} title={uptoStandard[`${typeOption}${item.level}`]}>
                                                                            <img src={require(`../../assets/images/challengesofGolden/award/${typeOption}${item.level}.png`).default} className='img-fluid challenge-award-img' alt={item.level} />
                                                                            <p className='text-light fw-bolder mt-2'>{item.name} x {kitem[`${typeOption}${item.level}`]}</p>
                                                                        </div>
                                                                    </>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </> : ''
                }
                {
                    option === '產生個人圖卡' &&
                    <>
                        <div className='d-flex justify-content-between my-3'>
                            <button className='btn btn-outline-challenges-yellow' id="save-local" onClick={handleEvent.downloadImg}>下載圖卡</button>
                            <p className='text-light text-end d-sm-none my-2'>往右滑動 ＞</p>
                        </div>
                        <div id="text" className='text-light'></div>
                        <div className='table-responsive-xxl text-center mt-1 mt-sm-3' id='card-table' style={{ height: '420px', overflowY: 'scroll' }}>
                            {
                                <table className='table table-striped table-light align-middle'>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className='position-relative' style={{ width: '409px', height: '409px' }} id='challenge-card'>
                                                    <img src={CardImg} className='img-fluid' alt='公勝挑戰卡' width='409px' height='409px' />
                                                    <div className='card-yellow-box'></div>
                                                    <div className='position-absolute fw-bolder' style={{ top: '139px', left: '32px', fontSize: '18px' }}>
                                                        {
                                                            busCodeToName.map((item, index) => {
                                                                if (busCode == item.busCode) {
                                                                    return <p>單位：<span className='text-dark-blue'>{item.name}</span></p>
                                                                }
                                                            })
                                                        }
                                                        <p>姓名：<span className='text-dark-blue'>{name}</span></p>
                                                    </div>
                                                    <p className='position-absolute fw-bolder text-dark-blue'
                                                        style={
                                                            handleEvent.calStrLength(toCurrency(Number(totalDonate) + Number(eventDonate))) == 1 ? { top: '114px', right: '73px', fontSize: '20px' } :
                                                                handleEvent.calStrLength(toCurrency(Number(totalDonate) + Number(eventDonate))) == 2 ? { top: '114px', right: '66px', fontSize: '20px' } :
                                                                    handleEvent.calStrLength(toCurrency(Number(totalDonate) + Number(eventDonate))) == 3 ? { top: '114px', right: '61px', fontSize: '20px' } :
                                                                        handleEvent.calStrLength(toCurrency(Number(totalDonate) + Number(eventDonate))) == 4 ? { top: '114px', right: '53px', fontSize: '20px' } :
                                                                            handleEvent.calStrLength(toCurrency(Number(totalDonate) + Number(eventDonate))) == 5 ? { top: '114px', right: '53px', fontSize: '20px' } :
                                                                                handleEvent.calStrLength(toCurrency(Number(totalDonate) + Number(eventDonate))) == 6 ? { top: '114px', right: '46px', fontSize: '20px' } :
                                                                                    handleEvent.calStrLength(toCurrency(Number(totalDonate) + Number(eventDonate))) == 7 ? { top: '114px', right: '47px', fontSize: '16px' } :
                                                                                        handleEvent.calStrLength(toCurrency(Number(totalDonate) + Number(eventDonate))) > 7 ? { top: '116px', right: '47px', fontSize: '14px' } :
                                                                                            handleEvent.calStrLength(toCurrency(Number(totalDonate) + Number(eventDonate))) == 0 ? {} : ''
                                                        }
                                                    >{toCurrency(Number(totalDonate) + Number(eventDonate))}</p>
                                                    <p className='position-absolute fw-bolder text-dark-blue'
                                                        style={
                                                            handleEvent.calStrLength(toCurrency(Number(totalEvent))) == 1 ? { top: '227px', right: '73px', fontSize: '20px' } :
                                                                handleEvent.calStrLength(toCurrency(Number(totalEvent))) == 2 ? { top: '227px', right: '66px', fontSize: '20px' } :
                                                                    handleEvent.calStrLength(toCurrency(Number(totalEvent))) == 3 ? { top: '227px', right: '61px', fontSize: '20px' } :
                                                                        handleEvent.calStrLength(toCurrency(Number(totalEvent))) == 4 ? { top: '227px', right: '53px', fontSize: '20px' } :
                                                                            handleEvent.calStrLength(toCurrency(Number(totalEvent))) == 5 ? { top: '227px', right: '53px', fontSize: '20px' } :
                                                                                handleEvent.calStrLength(toCurrency(Number(totalEvent))) == 6 ? { top: '227px', right: '46px', fontSize: '20px' } :
                                                                                    handleEvent.calStrLength(toCurrency(Number(totalEvent))) == 7 ? { top: '227px', right: '47px', fontSize: '16px' } :
                                                                                        handleEvent.calStrLength(toCurrency(Number(totalEvent))) > 7 ? { top: '228px', right: '47px', fontSize: '14px' } :
                                                                                            handleEvent.calStrLength(toCurrency(Number(totalEvent))) == 0 ? {} : ''
                                                        }>
                                                        {toCurrency(Number(totalEvent))}
                                                    </p>
                                                    <p className='position-absolute fw-bolder text-dark-blue'
                                                        style={
                                                            handleEvent.calStrLength(toCurrency(totalAward)) == 1 ? { top: '340px', right: '73px', fontSize: '20px' } :
                                                                handleEvent.calStrLength(toCurrency(totalAward)) == 2 ? { top: '340px', right: '66px', fontSize: '20px' } :
                                                                    handleEvent.calStrLength(toCurrency(totalAward)) == 3 ? { top: '340px', right: '61px', fontSize: '20px' } :
                                                                        handleEvent.calStrLength(toCurrency(totalAward)) == 4 ? { top: '340px', right: '53px', fontSize: '20px' } :
                                                                            handleEvent.calStrLength(toCurrency(totalAward)) == 5 ? { top: '340px', right: '53px', fontSize: '20px' } :
                                                                                handleEvent.calStrLength(toCurrency(totalAward)) == 6 ? { top: '340px', right: '46px', fontSize: '20px' } :
                                                                                    handleEvent.calStrLength(toCurrency(totalAward)) == 7 ? { top: '340px', right: '47px', fontSize: '16px' } :
                                                                                        handleEvent.calStrLength(toCurrency(totalAward)) > 7 ? { top: '339px', right: '47px', fontSize: '14px' } :
                                                                                            handleEvent.calStrLength(toCurrency(totalAward)) == 0 ? {} : ''
                                                        }
                                                    >
                                                        {toCurrency(totalAward)}
                                                    </p>
                                                    {/* 統計日期 */}
                                                    <p className='position-absolute fw-bolder text-challenges-yellow small' style={{ bottom: '5px', left: '66px', textShadow: '0 0 3px black' }}>
                                                        {moment().format('YYYY-MM-DD')}
                                                    </p>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            }
                        </div>
                        <div id="img-out" className="my-image"></div>
                    </>
                }
                <div className='text-end'>
                    <button className='btn btn-outline-light my-3 return-icon' onClick={handleEvent.returnHomePage}>返回首頁</button>
                </div>
            </div>
            <Modal show={modalShow} onHide={handleEvent.modalClose}>
                <Modal.Header closeButton>修改</Modal.Header>
                <Modal.Body>
                    <div className='mb-3'>
                        <label htmlFor='item' className='fw-bolder'>請選擇登記之挑戰項目<span className='text-danger'>*</span></label>
                        <select className='form-select' id='item' name='item'
                            onChange={e => setUpdateData({ ...updateData, r_type: e.target.value })}>
                            <option value='0' selected={updateData.r_type == '' ? 'selected' : ''} disabled>五大挑戰項目-累計里程</option>
                            <option value='1' selected={updateData.r_type == '1' ? 'selected' : ''}>健行 - 1元/K</option>
                            <option value='2' selected={updateData.r_type == '2' ? 'selected' : ''}>跑步 - 1元/K</option>
                            <option value='3' selected={updateData.r_type == '3' ? 'selected' : ''}>自行車 - 0.5元/K</option>
                            <option value='4' selected={updateData.r_type == '4' ? 'selected' : ''}>登山 - 3元/爬升100M</option>
                            <option value='5' selected={updateData.r_type == '5' ? 'selected' : ''}>游泳 - 2元/100M</option>
                        </select>
                    </div>
                    <div className='my-3'>
                        <label htmlFor='date' className='fw-bolder'>活動日期<span className='text-danger'>*</span></label>
                        <input id='date' name='date' type='date' className={`form-control`}
                            onChange={e => setUpdateData({ ...updateData, r_date: e.target.value })}
                            defaultValue={updateData.r_date} />
                    </div>
                    <div className="my-3">
                        <label htmlFor="done" className="fw-bolder">達成特級標準</label>
                        <div className="d-flex" onChange={handleEvent.changeMaxRadio}>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value='1' checked={updateData.r_premium_status == '1' ? 'checked' : ''} />
                                <label class="form-check-label" for="flexRadioDefault1">
                                    是
                                </label>
                            </div>
                            <div class="form-check ms-4">
                                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value='0' checked={updateData.r_premium_status == '0' ? 'checked' : ''} />
                                <label class="form-check-label" for="flexRadioDefault2">
                                    否
                                </label>
                            </div>
                        </div>
                    </div>
                    {
                        updateData.r_premium_status == '1' &&
                        <div className="my-3">
                            <label htmlFor="done" className="fw-bolder">完成活動名稱</label>
                            <input id="done" name="done" type="text" className={`form-control`}
                                value={
                                    updateData.r_challenges_name == '0' || updateData.r_challenges_name == null || updateData.r_challenges_name == undefined || updateData.r_challenges_name == '' ? '' : updateData.r_challenges_name
                                }
                                onChange={e => setUpdateData({ ...updateData, r_challenges_name: e.target.value })} />
                        </div>
                    }
                    <div className='my-3'>
                        <label htmlFor='milage' className='fw-bolder'>請輸入里程數
                            <span className='text-danger'>*</span>
                        </label>
                        <div className='input-group'>
                            <input id='milage' name='milage' type='tel' className={`form-control`}
                                onChange={e => setUpdateData({ ...updateData, r_records: e.target.value })}
                                defaultValue={updateData.r_records}
                            />
                            <span className='input-group-text bg-challenges-blue text-light'>
                                {updateData.r_type === '4' ? 'M（實際海拔爬升）' : ''}
                                {updateData.r_type === '5' ? 'M' : ''}
                                {updateData.r_type === '1' || updateData.r_type === '2' || updateData.r_type === '3' ? 'K（里程數）' : ''}
                            </span>
                        </div>
                    </div>
                    <div className='text-center py-3'>
                        <button type='button' className='btn btn-challenges-yellow fw-bolder w-100'
                            onClick={handleEvent.clickSend}>修改</button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default ChallengesofGoldenAward