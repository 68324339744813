import React from 'react';
const StepList = ({ data, stepNum }) => {
    return (
        <ul className='list-unstyled steps'>
            {data.map((item, index, ary) => {
                return (
                    <li key={`data-${index + 1}`} className={`${Number(stepNum) > index + 1 || Number(stepNum) == ary.length ? 'done' : ''} ${Number(stepNum) == index + 1 ? 'active' : ''}`}>
                        <div className='mb-2' style={{ fontSize: '18px' }}>
                            {item?.step}
                        </div>
                        <div className='pre'>{item?.text}</div>
                    </li>
                );
            })}
        </ul>
    );
};

export default StepList;
